<template>
  <v-dialog
    v-model="showingContactModal"
    width="800"
    scrollable
    persistent
    minHeight="1200"
  >
    <v-form ref="contactForm" @submit.prevent="createContact">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editContactItem ? "Editar Contacto" : "Crear Contacto" }}
        </v-card-title>

        <v-card-text style="max-height: 500px">
          <v-row class="mt-2">
            <v-col cols="6" md="6">
              <v-text-field
                ref="name"
                v-model="form_name"
                :rules="[(v) => !!v || 'Nombre requerido']"
                outlined
                dense
                autocomplete="off"
                hide-details
                label="Nombre del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="dni"
                v-model="form_dni"
                :rules="[(v) => !!v || 'DNI requerido']"
                outlined
                dense
                autocomplete="off"
                type="number"
                hide-details
                label="DNI del contacto"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-divider class="my-6"></v-divider>

          <v-row v-for="(number, idx) in form_numbers">
            <v-col cols="3" md="3">
              <v-select
                id="selectorflags"
                name="selectorflags"
                v-model="number.selectorFlags"
                :items="items"
                label="Cod. Area"
                :rules="[(v) => !!v || 'Caracteristica requerida']"
                required
                hide-details="auto"
                outlined
                dense
                @change="changePrefix($event, idx)"
              ></v-select>
            </v-col>

            <v-col cols="7" md="7">
              <v-text-field
                v-model="number.form_phone"
                type="number"
                outlined
                :rules="[
                  (v) => !!v || 'El número es necesario',
                  (v) => (v && v.length > 7) || 'Mínimo 8 números',
                ]"
                dense
                hide-details
                placeholder="Número de Whatsapp"
                label="Whatsapp"
              ></v-text-field>
            </v-col>

            <v-col cols="2" md="2">
              <v-btn
                icon
                color="error"
                @click="removeNumber(idx)"
              >
                <v-icon>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" md="12">
              <v-radio-group
                style="margin-top: 0;"
                v-model="number.correct"
                row
              >
                <v-radio
                  label="Número confirmado"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="Número no es correcto"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Sin datos de confirmación"
                  :value="null"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="12" md="12" class="d-flex align-center pt-0 pb-0">
                  <label v-if="editContactItem" class="extra_label">Datos Extra</label>
                  <v-checkbox
                    v-if="!editContactItem"
                    v-model="number.show_datos"
                    label="Datos Extra"
                    @change="resetDatoInputs(idx)"
                    class="mt-0"
                  ></v-checkbox>
                  <v-btn
                    icon
                    color="primary"
                    @click="addDatoInput(idx)"
                    v-if="number.show_datos"
                    :disabled="number.form_datos_show > 9"
                  >
                    <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="number.form_link1"
                    outlined
                    :disabled="!number.show_datos && !number.form_link1 && !editContactItem"
                    dense
                    autocomplete="off"
                    hide-details
                    label="Link 1 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="number.form_link2"
                    outlined
                    :disabled="!number.show_datos && !number.form_link2 && !editContactItem"
                    dense
                    autocomplete="off"
                    hide-details
                    label="Link 2 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="number.form_email"
                    outlined
                    :disabled="!number.show_datos && !number.form_email && !editContactItem"
                    dense
                    autocomplete="off"
                    hide-details
                    label="Email del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="number.form_dato1"
                    outlined
                    :disabled="!number.show_datos && !number.form_dato1 && !editContactItem"
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 1 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 2 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato2"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 2 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 3 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato3"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 3 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 4 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato4"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 4 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 5 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato5"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 5 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 6 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato6"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 6 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 7 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato7"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 7 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 8 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato8"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 8 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 9 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato9"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 9 del contacto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="6" md="6" v-if="number.form_datos_show >= 10 || editContactItem">
                  <v-text-field
                    v-model="number.form_dato10"
                    outlined
                    
                    dense
                    autocomplete="off"
                    hide-details
                    label="Dato 10 del contacto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-4"></v-divider>
            </v-col>

          </v-row>

          <v-btn
            icon
            color="primary"
            class="mb-4"
            @click="addNumber"
          >
            <v-icon>
              {{ icons.mdiPlusCircleOutline }}
            </v-icon>
          </v-btn>

          <v-row>
            <v-col cols="12" md="12" class="pt-0 pb-0">
              <v-autocomplete
              v-model="form_tags"
              :items="companyTagsData"
              item-text="name"
              item-value="id"
              chips
              deletable-chips
              :search-input.sync="searchTagsQuery"
              label="Seleccione uno o varios tags"
              outlined
              dense
              multiple
              >
                <template #item="data">
                  <v-list-item-content @click="searchTagsQuery = ''"> <v-list-item-title>{{ data.item.name }}</v-list-item-title> </v-list-item-content>
                </template>
                <template v-slot:no-data>
                  <v-list-item> <v-list-item-title> No se encontraron <strong>Tags</strong> </v-list-item-title> </v-list-item>
                </template>
              </v-autocomplete>
              <!-- <v-autocomplete
                v-model="form_tags"
                :items="companyTagsData"
                :filter="searchTags"
                label="Seleccione uno o varios tags"
                outlined
                dense
                multiple
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                  >
                    <span class="pr-2">
                      {{ item.name }}
                    </span>
                    <v-icon @click="parent.selectItem(item)" small> $delete </v-icon>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip label>
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete> -->
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="12" md="12" class="d-flex align-center pt-0">
              <label v-if="editContactItem" class="extra_label">Datos Extra</label>
              <v-checkbox
                v-if="!editContactItem"
                v-model="show_datos"
                label="Datos Extra"
                @change="resetDatoInputs()"
              ></v-checkbox>
              <v-btn
                icon
                color="primary"
                @click="addDatoInput()"
                v-if="show_datos"
                :disabled="form_datos_show > 9"
              >
                <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_link1"
                outlined
                :disabled="!show_datos && !form_link1 && !editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Link 1 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_link2"
                outlined
                :disabled="!show_datos && !form_link2 && !editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Link 2 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_email"
                outlined
                :disabled="!show_datos && !form_email && !editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Email del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato1"
                outlined
                :disabled="!show_datos && !form_dato1 && !editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 1 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato2"
                outlined
                v-if="form_datos_show >= 2 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 2 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato3"
                outlined
                v-if="form_datos_show >= 3 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 3 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato4"
                outlined
                v-if="form_datos_show >= 4 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 4 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato5"
                outlined
                v-if="form_datos_show >= 5 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 5 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato6"
                outlined
                v-if="form_datos_show >= 6 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 6 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato7"
                outlined
                v-if="form_datos_show >= 7 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 7 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato8"
                outlined
                v-if="form_datos_show >= 8 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 8 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato9"
                outlined
                v-if="form_datos_show >= 9 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 9 del contacto"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                v-model="form_dato10"
                outlined
                v-if="form_datos_show >= 10 || editContactItem"
                dense
                autocomplete="off"
                hide-details
                label="Dato 10 del contacto"
              ></v-text-field>
            </v-col>
          </v-row> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <span class="error--text error-msg" v-if="error_msg">{{ error_msg }}</span>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="closeModal()"> CANCELAR </v-btn>

          <v-btn color="primary" type="submit">
            {{ editContactItem ? "EDITAR CONTACTO" : "CREAR CONTACTO" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
const debounce = require('debounce');
import { ref } from "@vue/composition-api";
import { mdiPlusCircleOutline, mdiTrashCanOutline } from "@mdi/js";

export default {
  data: () => {
    return {
      form_name: "",
      form_dni: "",
      
      form_numbers: [
        {
          selectorFlags: "🇦🇷 (+549)",
          form_phone: "", 
          form_prefix: 549, 
          correct: null, 
          form_datos_show: 1,
          form_link1: null,
          form_link2: null,
          form_email: null,
          form_dato1: null,
          form_dato2: null,
          form_dato3: null,
          form_dato4: null,
          form_dato5: null,
          form_dato6: null,
          form_dato7: null,
          form_dato8: null,
          form_dato9: null,
          form_dato10: null,
          show_datos: false,
        }
      ],
      form_company: null,
      form_tags: null,
      showingContactModal: false,
      editContactItem: null,
      error_msg: null,
      companyTagsData: [],
      searchTagsQuery: '',
    };
  },

  methods: {
    openModal(item_to_edit) {
      //this.form_numbers = {form_prefix: 549, selectorFlags: "🇦🇷 (+549)", form_phone: ""}
      this.getTagsData();
      item_to_edit ? this.setItemToEdit(item_to_edit) : (this.showingContactModal = true);
    },

    addDatoInput(idx) {
      this.form_numbers[idx].form_datos_show += 1;
    },

    resetDatoInputs(idx) {
      this.form_numbers[idx].form_link1 = null;
      this.form_numbers[idx].form_link2 = null;
      this.form_numbers[idx].form_email = null;
      this.form_numbers[idx].form_dato1 = null;
      this.form_numbers[idx].form_dato2 = null;
      this.form_numbers[idx].form_dato4 = null;
      this.form_numbers[idx].form_dato5 = null;
      this.form_numbers[idx].form_dato6 = null;
      this.form_numbers[idx].form_dato7 = null;
      this.form_numbers[idx].form_dato8 = null;
      this.form_numbers[idx].form_dato9 = null;
      this.form_numbers[idx].form_dato10 = null;
      this.form_numbers[idx].form_datos_show = 1;
    },

    closeModal() {
      this.resetForm();
      this.editContactItem = null;
      this.error_msg = null;
      this.showingContactModal = false;
    },

    resetForm() {
      this.$refs.contactForm.reset();
      this.form_company = null;
      this.form_tags = null;
      this.form_numbers = [
        {
          selectorFlags: "🇦🇷 (+549)", 
          form_phone: "", 
          form_prefix: 549, 
          correct: null,
          form_datos_show: 1,
          form_link1: null,
          form_link2: null,
          form_email: null,
          form_dato1: null,
          form_dato2: null,
          form_dato3: null,
          form_dato4: null,
          form_dato5: null,
          form_dato6: null,
          form_dato7: null,
          form_dato8: null,
          form_dato9: null,
          form_dato10: null,
          show_datos: false,
        }
      ]
    },

    async getTagsData() {
      const query = {
        page: 1,
        page_size: 10,
        search: this.searchTagsQuery,
        company_id: this.getSelectedCompany,
      };

      try {
        const response = await this.$api.getTags(query);
        this.companyTagsData = response.results;
      } catch (error) {
        console.log("Error getTags: ", error);
      }
    },

    handleTextsSearch: debounce(function (e) {
      this.getTagsData()
    }, 300),

    setItemToEdit(item_to_edit) {
      const item = item_to_edit;

      if (item) {
        this.editContactItem = item;

        //this.form_prefix = parseInt(item.prefix);
        // this.items.forEach((el) => {
        //   if (el.includes(item.prefix)) {
        //     this.selectorFlags = el;
        //   }
        // });

        this.form_numbers = item.phones.map(el => {
          return {
            form_phone: el.number,
            form_prefix: el.prefix,
            selectorFlags: '',
            correct: el.correct,
            form_link1: el.link1,
            form_link2: el.link2,
            form_email: el.email,
            form_dato1: el.dato1,
            form_dato2: el.dato2,
            form_dato3: el.dato3,
            form_dato4: el.dato4,
            form_dato5: el.dato5,
            form_dato6: el.dato6,
            form_dato7: el.dato7,
            form_dato8: el.dato8,
            form_dato9: el.dato9,
            form_dato10: el.dato10,
          }
        })

        this.form_numbers.forEach(number => {
          this.items.forEach(flag => {
            if(flag.includes(number.form_prefix)) {
              number.selectorFlags =flag
            }
          })
        })

        console.log(item.phones)

        console.log(this.form_numbers)

        this.form_name = item.name;
        this.form_dni = item.dni;
        this.form_company = this.getAllCompaniesList.find(
          (el) => el.id === item.company_id
        );

        const tagsIds = item.tags.map((el) => el.id);
        this.form_tags = tagsIds

        //this.form_phone = item.phone;
        
      }
      this.showingContactModal = true;
    },

    changePrefix(e, idx) {
      const matches = e.match(/\d+/g);

      if (matches) this.form_numbers[idx].form_prefix = Number(matches[0]);
    },

    addNumber() {
      this.form_numbers.push({
        selectorFlags: "🇦🇷 (+549)", 
        form_phone: "", 
        form_prefix: 549, 
        correct: null,
        form_datos_show: 1,
        form_link1: null,
        form_link2: null,
        form_email: null,
        form_dato1: null,
        form_dato2: null,
        form_dato3: null,
        form_dato4: null,
        form_dato5: null,
        form_dato6: null,
        form_dato7: null,
        form_dato8: null,
        form_dato9: null,
        form_dato10: null,
        show_datos: false,
      })
    },

    removeNumber(idx) {
      this.form_numbers.splice(idx, 1)
    },

    searchTags(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(item.name);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },

    async createContact() {
      if (this.$refs.contactForm.validate()) {
        const form = {
          name: this.form_name,
          tags: this.form_tags ?? [],
          dni: this.form_dni,
          company: this.getSelectedCompany,
        };

        form.phones = this.form_numbers.map((el, idx) => {
          return {
            prefix: el.form_prefix,
            phone: el.form_phone,
            order: idx,
            correct: el.correct,
            company: this.getSelectedCompany,
            link1: el.form_link1 ?? "",
            link2: el.form_link2 ?? "",
            email: el.form_email ?? "",
            dato1: el.form_dato1 ?? "",
            dato2: el.form_dato2 ?? "",
            dato3: el.form_dato3 ?? "",
            dato4: el.form_dato4 ?? "",
            dato5: el.form_dato5 ?? "",
            dato6: el.form_dato6 ?? "",
            dato7: el.form_dato7 ?? "",
            dato8: el.form_dato8 ?? "",
            dato9: el.form_dato9 ?? "",
            dato10: el.form_dato10 ?? "",
          }
        })

        console.log(form)
        try {
          if(this.editContactItem) {
            delete form.company
          }
          const response = this.editContactItem
            ? await this.$api.updateContact(this.editContactItem.id, form)
            : await this.$api.createContact(form);
          this.$emit("reload-contact-list");
          this.closeModal();
        } catch (e) {
          console.log(e);
          if  (e?.response?.status === 400) {
            console.log(e.response.data);
            this.error_msg = e?.response?.data?.non_field_errors[0]
          }
          if (e?.response?.status === 403) {
            console.log(e.response.data);
            this.error_msg = e?.response?.data?.message;
          }
        }
      }
    },
  },
  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
    getAllCompaniesList() {
      return this.$store.getters["session/getAllCompaniesList"]();
    },
  },
  watch: {
    searchTagsQuery(val) {
      this.handleTextsSearch();
    },
  },
  setup() {
    const items = [
      "🇦🇷 (+549)",
      "🇧🇷 (+55)",
      "🇧🇴 (+591)",
      "🇨🇱 (+56)",
      "🇨🇴 (+57)",
      "🇪🇨 (+593)",
      "🇲🇽 (+52)",
      "🇵🇪 (+51) ",
      "🇵🇾 (+595)",
      "🇺🇾 (+598)",
      "🇻🇪 (+58)",
    ];

    return {
      items,
      icons: {
        mdiPlusCircleOutline,
        mdiTrashCanOutline
      },
    };
  },
};
</script>

<style>
.extra_label {
  font-size: 16px;
  color: rgba(94, 86, 105, 0.68);
}
</style>
